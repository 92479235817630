<template>
  <div>
    <div class="loading">
      <div>LOADING</div>
    </div>
    <div class="progress-bar">
      <div class="progress-bar__fill" :style="`width: ${progress_bar_with_load}%;`">{{progress_bar_with_load}}%</div>
    </div>
  </div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
  cnt: Number,
  cur: Number
})

function progress_bar_with_load_calc()
{
  let res=0

  if(props.cnt>props.cur && props.cur>0) {
    res=Math.round(props.cur/props.cnt*100)
  }

  return res
}
const progress_bar_with_load = computed(progress_bar_with_load_calc)
</script>

<style scoped>
.loading {
  display: flex;
  justify-content: center;
  position: absolute;
  top:0;
  width: 1920px;
  height: 1080px;
  background: #000000;
  z-index: 1000;
  color: white;
  text-align: center;
}

.loading div {
  width: 400px;
  height: 100px;
  margin: auto;
  font-size: 72pt;
}

.progress-bar {
  position: relative;
  top: 300px;
  left: 60px;
  width: 1800px;
  z-index: 1100;
  overflow: hidden;
  margin-bottom: 3px;
}

.progress-bar__fill {
  height: 3px;
  width: 100%;
  height: 50px;
  font-size: 24pt;
  font-weight: bold;
  background-color: #FFDD73;
  background-repeat: repeat;
  background-size: 100%;
  background-position-y: 0px;
  background-position-x: 0px;
  -webkit-transition: all linear 1s;
  -o-transition: all linear 1s;
  transition: all linear 1s;
}
</style>