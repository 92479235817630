import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faUserSecret, faLock } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faUserSecret)
library.add(faLock)

let app = createApp(App)

fetch("./config/main.json")
.then((response) => response.json())
.then((config) => {
        app.provide('app_config', config)
        app.use(router, config)
        app.use(store)
        app.use(i18n)
        app.component('font-awesome-icon', FontAwesomeIcon)
        app.mount("#app")
})

/*app.config.globalProperties.config={"sss": 'asdsa'}
app.use(router)
app.use(store)
app.use(i18n)
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')*/
