import { createWebHistory, createRouter } from "vue-router";
import Login from "@/components/Login.vue";
// lazy-loaded
const Home = () => import("@/components/Home.vue")

let routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
  }
];

if(process.env.VUE_APP_GAME=='keno' || process.env.VUE_APP_GAME=='') {
  const Keno = () => import("@/components/Keno/Keno.vue")

  routes.push({
    path: "/keno",
    name: "Keno",
    // lazy-loaded
    component: Keno
  })
}

if(process.env.VUE_APP_GAME=='kenoext' || process.env.VUE_APP_GAME=='') {
  const Kenoext = () => import("@/components/Kenoext/Kenoext.vue")

  routes.push({
    path: "/kenoext",
    name: "Kenoext",
    // lazy-loaded
    component: Kenoext
  })
}

if(process.env.VUE_APP_GAME=='race' || process.env.VUE_APP_GAME=='') {
  const Race = () => import("@/components/Race/Race.vue")

  routes.push({
    path: "/race",
    name: "Race",
    // lazy-loaded
    component: Race
  })
}

if(process.env.VUE_APP_GAME=='cock' || process.env.VUE_APP_GAME=='') {
  const Race = () => import("@/components/Cock/Main.vue")

  routes.push({
    path: "/cock",
    name: "Cock",
    // lazy-loaded
    component: Race
  })
}

if(process.env.VUE_APP_GAME=='moto' || process.env.VUE_APP_GAME=='') {
  const Moto = () => import("@/components/Moto/Main.vue")

  routes.push({
    path: "/moto",
    name: "Moto",
    // lazy-loaded
    component: Moto
  })
}

if(process.env.VUE_APP_GAME=='horse' || process.env.VUE_APP_GAME=='') {
  const Horse = () => import("@/components/Horse/Main.vue")

  routes.push({
    path: "/horse",
    name: "Horse",
    // lazy-loaded
    component: Horse
  })
}

if(process.env.VUE_APP_GAME=='dog' || process.env.VUE_APP_GAME=='') {
  const Dog = () => import("@/components/Dog/Main.vue")

  routes.push({
    path: "/dog",
    name: "Dog",
    // lazy-loaded
    component: Dog
  })
}

const router = createRouter({
  history: createWebHistory('#'),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/home'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = sessionStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    sessionStorage.setItem('user', '{}')
    next('/login')
  } else {
     next();
  }
});

router.afterEach((to, from) => {
  let title=to.name
  document.title = title;
  /*Vue.nextTick(() => {
    document.title = title;
  });*/
});

export default router;